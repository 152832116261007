import React from 'react';
import { BrowserRouter as Router, Routes, Link, Route, Navigate } from 'react-router-dom';

import Home from './components/Home';
import Classified from './components/Classified';
import EditClassified from './components/EditClassified';

const App = () => {
  return (
    <Router>
      <div className='container-fluid'>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light">
            {/* <button className="navbar-toggler navbar-toggler-sm" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon btn-sm"></span>
            </button> */}

            <h4>classifieds.aktulip.org</h4>

            {/* <Link className="navbar-brand" to="/"><b>classifieds.aktulip.org</b></Link> */}
            <div className="collapse navbar-collapse" id="navbarNav">

              <ul className="navbar-nav">
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/PostClassified">Post Ad</Link>
                </li> */}
              </ul>
            </div>
          </nav>

          <Routes>
            {/* Use Navigate to redirect to the home page */}
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home/:Email" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/PostClassified" element={<Classified />} />
            <Route path="/EditClassified" element={<EditClassified />} />
          </Routes>

        </div>
      </div>
    </Router>
  );
};

export default App;
