import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './home.css';

const Home = () => {

  const { Email: paramEmail } = useParams();
  const [email, setEmail] = useState(sessionStorage.getItem('Email'));

  useEffect(() => {
    // If email is not already in sessionStorage, use the parameter from the URL
    if (!email && paramEmail) {
      sessionStorage.setItem('Email', paramEmail);
      setEmail(paramEmail);
    }
  }, [email, paramEmail]);

  // const { Email } = useParams();  // Extract FormID from the route parameters
  // sessionStorage.setItem('Email', Email);

  console.log("Email: " + sessionStorage.getItem('Email'));

  const [classifieds, setClassifieds] = useState([]);
  const [selectedFlat, setSelectedFlat] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState('all'); // State variable for filter
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://api.datalabs.info/api/aktulip/GetClassifieds');
        const data = await response.json();
        setClassifieds(data);
      } catch (error) {
        console.error('Error fetching classifieds:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleReadMore = (flat) => {
    setSelectedFlat(flat);
    setShowModal(true);
  };

  const handleEdit = (flat) => {
    setSelectedFlat(flat);
    navigate('/EditClassified?FlatNo=' + flat);
  };

  const handleDelete = async (flat) => {
    try {
      const response = await fetch(`https://api.datalabs.info/api/aktulip/DeleteClassifiedAd/${flat.id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        setClassifieds(classifieds.filter((item) => item.id !== flat.id));
        console.log('Item deleted successfully.');
      } else {
        console.error('Error deleting item:', response.status);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // Filter classifieds based on selected filter
  const filteredClassifieds = classifieds.filter((classified) => {
    if (filter === 'all') {
      return true;
    } else if (filter === 'Flat for Rent' || filter === 'Flat for Sale') {
      return classified.Title.toLowerCase() === filter.toLowerCase();
    } else if (filter === 'recent') {
      // Assuming the classifieds have a 'postedDate' field
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      const classifiedDate = new Date(classified.postedDate);
      return classifiedDate >= oneWeekAgo;
    }
    return false;
  });

  return (
    <div className='row' >

      {sessionStorage.getItem('Email') == 'aktulip.hd@gmail.com' && (
        <div><Link className="btn btn-primary btn-sm" to="/PostClassified">Post Ad</Link></div>
      )}

      <div className="filter-buttons">
        <button className={filter === 'all' ? 'active' : ''} onClick={() => setFilter('all')}> <b>All</b></button>
        <button className={filter === 'Flat for Rent' ? 'active' : ''} onClick={() => setFilter('Flat for Rent')}>Rent</button>
        <button className={filter === 'Flat for Sale' ? 'active' : ''} onClick={() => setFilter('Flat for Sale')}>Sale</button>
      </div>

      
      { !isLoading && filteredClassifieds && filteredClassifieds.length < 1 && (
        <div className='text-danger'>No Data Found</div>
      )} 
      
      <div className="container-fluid">
        <div className="row mt-3">
       
          {filteredClassifieds.map((classified, index) => (
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <h6 className="card-title text-danger">{classified.FlatNo}</h6>
                  <p><strong>{classified.Title}</strong></p>
                  <p><strong>Area:</strong> {classified.Area} sft

                  &nbsp;&nbsp;&nbsp;<strong>Price (&#x20b9;):</strong> {classified.Price}
                  </p>
                  <button onClick={() => handleReadMore(classified)} style={{ width: '60px' }}  className="btn-primary btn-sm">View</button>
                  &nbsp;
                  {sessionStorage.getItem('Email') == 'aktulip.hd@gmail.com' && (
                    <button onClick={() => handleEdit(classified.FlatNo)} style={{ width: '60px' }}  className="btn-success btn-sm">Edit</button>
                  )}                  
                </div>
              </div>
            </div>

          ))}
          
        </div>
        <div className='row mt-2'>
          <div className='col-md-4'>
          Are you Owner of a Flat?<br/> Contact <b>Club House Help Desk</b> to Post Ad on Flat for Rent/Sale.
          </div>
        </div>
      </div>


      {/* Modal */}
      {showModal && selectedFlat && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{selectedFlat.Title}</h5>
                <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p><strong>Flat No:</strong> {selectedFlat.FlatNo}</p>
                <p><strong>Description:</strong> {selectedFlat.Description}</p>
                <p><strong>Bedrooms:</strong> {selectedFlat.BedRooms}</p>
                <p><strong>Bathrooms:</strong> {selectedFlat.BathRooms}</p>
                <p><strong>Furnishing:</strong> {selectedFlat.Furnishing}</p>
                <p><strong>Area:</strong> {selectedFlat.Area} sft</p>
                <p><strong>Bachelors Allowed:</strong> {selectedFlat.BachelorsAllowed}</p>
                <p><strong>Car Parking:</strong> {selectedFlat.CarParking}</p>
                <p><strong>Facing:</strong> {selectedFlat.Facing}</p>
                <p><strong>Price:</strong> &nbsp; &#x20b9; {selectedFlat.Price} </p>
                <p><strong>Status:</strong> {selectedFlat.Status}</p>
                <p>Contact <span className='text-primary'>aktulip.hd@gmail.com </span> for more details</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>

  );
};


export default Home;
