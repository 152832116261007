import React, { useState } from 'react';
import './Classified.css';
import { useNavigate, Link } from 'react-router-dom';

const Classified = ({ onRegister }) => {
  const email = sessionStorage.getItem('Email');

  const [formData, setFormData] = useState({
    FlatNo: '',
    Title: '',
    Description: '',
    BedRooms: '',
    BathRooms: '',
    Furnishing: '',
    Area: '',
    BachelorsAllowed: '',
    CarParking: '',
    Facing: '',
    Price: '',
    Status: ''
  });

  const [successMessage, setSuccessMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSuccessMessage('');
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://api.datalabs.info/api/aktulip/PostClassifiedAd', { // Replace with your actual backend URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData), // Convert object to JSON string
    })
      .then((response) => {
        if (response.ok) {
          setSuccessMessage('Successfully posted.'); // Set success message
          if (onRegister) {
            onRegister(formData);
          }
          // Clear form data after successful submission
          setFormData({
            FlatNo: '',
            Title: '',
            Description: '',
            BedRooms: '',
            BathRooms: '',
            Furnishing: '',
            Area: '',
            BachelorsAllowed: '',
            CarParking: '',
            Facing: '',
            Price: '',
            Status: ''
          });
        } else {
          console.error('Error registering form');
        }
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div className='col-md-4'>
      <form  onSubmit={handleSubmit}>
        <div className='mb-3'>
          <span style={{ fontSize:'24px' }}><b>Post AD</b></span> 
          &nbsp; <Link className="btn btn-primary btn-sm mb-1" to={`/Home`}>Back</Link>
        </div>
        <p className="field required">
          <label className="label required" htmlFor="flatNo">Flat No</label>
          <input className="text-input" type="text" id="flatNo" name="FlatNo" value={formData.FlatNo} onChange={handleInputChange} required />
        </p>
        <p className="field required">
          <label className="label required" htmlFor="title">Flat for Rent/ Sale</label>
          <select id="title" name="Title" value={formData.Title} style={{ width: '200px' }} onChange={handleInputChange} required>
            <option value="">Select for Sale/ Rent</option>
            <option value="Flat for Rent">Flat for Rent</option>
            <option value="Flat for Sale">Fla for Sale</option>
          </select>
        </p>
        <p className="field">
          <label className="label" htmlFor="description">Description</label>
          <textarea className="textarea" id="description" name="Description" value={formData.Description} onChange={handleInputChange} cols="50" rows="2"></textarea>
        </p>
        <p className="field required half">
          <label className="label required" htmlFor="bedrooms">Bedrooms</label>
          <select id="bedrooms" name="BedRooms" value={formData.BedRooms} style={{ width: '200px' }} onChange={handleInputChange} required>
            <option value="">Select Bedrooms</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </p>
        <p className="field required half">
          <label className="label required" htmlFor="bathrooms">Bathrooms</label>
          <select id="bathrooms" name="BathRooms" value={formData.BathRooms} style={{ width: '200px' }} onChange={handleInputChange} required>
            <option value="">Select Bathrooms</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </p>
        <p className="field required">
          <label className="label required" htmlFor="furnishing">Furnishing</label>
          <select id="furnishing" name="Furnishing" value={formData.Furnishing} style={{ width: '200px' }} onChange={handleInputChange} required>
            <option value="">Select Furnishing</option>
            <option value="Full">Full Furnished</option>
            <option value="Semi">Semi-Furnished</option>
            <option value="Unfurnished">Un-furnished</option>
          </select>
        </p>
        <p className="field required">
          <label className="label required" htmlFor="area">Area (in Number eg: 1395)</label>
          <input className="text-input" type="text" id="area" name="Area" value={formData.Area} style={{ width: '200px' }} onChange={handleInputChange} required /> sft
        </p>
        <p className="field required half">
          <label className="label required" htmlFor="bachelorsAllowed">Bachelors Allowed</label>
          <select id="bachelorsAllowed" name="BachelorsAllowed" value={formData.BachelorsAllowed} style={{ width: '220px' }} onChange={handleInputChange} required>
            <option value="">Select Bachelors Allowed</option>
            <option value="Allowed">Allowed</option>
            <option value="Not Allowed">Not Allowed</option>
          </select>
        </p>
        <p className="field required half">
          <label className="label required" htmlFor="carParking">Car Parking</label>
          <select id="carParking" name="CarParking" value={formData.CarParking} style={{ width: '200px' }} onChange={handleInputChange} required>
            <option value="">Select Car Parking</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </p>
        <p className="field required">
          <label className="label required" htmlFor="facing">Facing</label>
          <select id="facing" name="Facing" value={formData.Facing} style={{ width: '200px' }}   onChange={handleInputChange} required>
            <option value="">Select Facing</option>
            <option value="East">East</option>
            <option value="West">West</option>
          </select>
        </p>
        <p className="field required">
          <label className="label required" htmlFor="price">Price</label>
          <input className="text-input" type="text" id="price" name="Price" value={formData.Price} style={{ width: '200px' }} onChange={handleInputChange} required />
        </p>
        <p className="field required half">
          <label className="label required" htmlFor="status">Status</label>
          <select id="status" name="Status" value={formData.Status} onChange={handleInputChange} required>
            <option value="">Select Status</option>
            <option value="Available">Available</option>
            <option value="Occupied">Occupied</option>
          </select>
        </p>
        {successMessage && (
          <p className="field success-message">{successMessage}</p>
        )}
        <p className="field">
          <input className="btn-primary btn-sm" type="submit" style={{ width: '120px' }} value="Post AD" />
          <Link className="btn btn-link btn-sm mb-1" style={{ width: '120px' }} to={`/Home/${email}`}>Back</Link>
        </p>
      </form>
    </div>
  );
};

export default Classified;
